import { gql } from "@apollo/client";

export const EstablishmentsListUser = gql`query($id: ID!, $role_id: [ID]) {
    data: EstablishmentsListUser(user_id: $id, role_id: $role_id) {
        id
        external_id
        company_id
        identifier_number
        legal_name
        slug
        status
        status_description
        country_id
        city_id
        active
        visible
        logo_url
        logo_thumbnail_url
        banner_url
        banner_thumbnail_url
        mapeable_old {
            oldable_type
            oldable_id
        }
        country {
            id
            name
            timezone
            lang
            zip_code
            currency_code
            flag
        }
        city {
            id
            name
        }
        address {
            id
            description
            country_id
            city_id
            addressable_type
            addressable_id
        },
        company {
            id
            country_id
            city_id
            legal_name
            mapeable_old {
                oldable_type
                oldable_id
            }
        }
    }
}
`

export const EstablishmentsListSetting = gql`query($eId: String!) {
    data: EstablishmentListSettings(establishment_id: $eId) {
        groups {
            label
            value
        }
        settings {
            id
            name
            label
            description
            group
            value
            type
            editable
            is_global
            data
            attributes
            adjustable_type
            adjustable_id
            city_id
            country_id
            position
        }
    }
}`

export const HomeDashboardQuery = gql`query($eId: String!, $start: String!, $end: String!) {
    data: HomeDashboard(establishment_id: $eId, start_date: $start, end_date: $end) {
        sales {
            series {
                name
                data
            }
            categories
        }
        orders {
            labels
            values
            coinSymbol
        }
        sales_amount
        sales_confirmed
    }
}`

export const HomePromotionsQuery = gql`query($eId: String!) {
    data: EstablishmentHomePromotions(establishment_id: $eId) {
        quantity_orders
        quantity_sales
        quantity_customers
    }
}`

export const EstablishmentListItemsQuery = gql`query($eId: String!, $search: String) {
    data: EstablishmentListItems(establishment_id: $eId, search: $search) {
        id
        external_id
        catalog_id
        establishment_id
        item_code
        item_code_gsl
        barcode
        name
        description
        price
        price_minimum
        price_promo
        thumbnail
        active
        visible
        favorite
        sliders {
            xs {
                url
                filename
                path
            }
            sm {
                url
                filename
                path
            }
            md {
                url
                filename
                path
            }
            lg {
                url
                filename
                path
            }
            xl {
                url
                filename
                path
            }
        }
        attributes {
            label
            value
        }
    }
}`

export const EstablishmentListAdministratorsQuery = gql`query ($eid: String!) {
    data: EstablishmentListUserAdministrators(establishment_id: $eid)
    {
        id
        external_id
        name
        email
        country_id
        avatar
    }
}`