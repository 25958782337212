import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { defaultClient, apolloMasterClient } from "apollo"
import { EstablishmentsListUser, HomeDashboardQuery } from "apollo/queries/establishments"
import { MasterListCategoriesQuery, MasterListCities, MasterListCountries, MasterListGenericTable, MasterListUsersQuery } from "apollo/queries/masters"
import { removeProps } from "utils/helpers"

export const listCountries = createAsyncThunk('base/admin/countries/list', async({ visible } = {}) => {
    const { data } = await apolloMasterClient.query({
        query: MasterListCountries,
        variables: {
            visible: typeof visible === 'undefined' ? true : visible,
        },
        fetchPolicy: 'network-only',
    })
    return data.data
})

export const listCities = createAsyncThunk('base/admin/cities/list', async({ countryId, query, pageIndex, pageSize }) => {
    const { data } = await apolloMasterClient.query({
        query: MasterListCities,
        variables: {
            countryId: countryId ? countryId : null,
            paginate: {
                page: pageIndex === undefined ? 1 : pageIndex,
                per_page: pageSize === undefined ? 100 : pageSize,
                search: query === undefined ? '' : query,
            }
        },
        fetchPolicy: 'network-only',
    })
    return {
        data: removeProps(data?.data?.data, ['__typename']),
        pageSize: data?.data?.per_page,
        pageIndex: data?.data?.current_page,
        pageTotal: data?.data?.last_page,
        total: data?.data?.total,
    }
})

export const listGenericTable = createAsyncThunk('base/admin/genericTable/list', async({ table, group, active, visible, query, pageIndex, pageSize }) => {
    const { data } = await apolloMasterClient.query({
        query: MasterListGenericTable,
        variables: {
            table,
            group,
            active: active === undefined ? true : active,
            visible: visible === undefined ? true : visible,
            paginate: {
                page: pageIndex === undefined ? 1 : pageIndex,
                per_page: pageSize === undefined ? 10 : pageSize,
                search: query === undefined ? '' : query,
            }
        },
        fetchPolicy: 'network-only'
    })
    return {
        data: data.data.data,
        pageSize: data.data.per_page,
        pageIndex: data.data.current_page,
        pageTotal: data.data.last_page,
        total: data.data.total,
    }
})

export const listEstablishmentsUser = createAsyncThunk('base/admin/estabishments-user', async({id, role_id}) => {
    const { data } = await defaultClient.query({
        query: EstablishmentsListUser,
        variables: {
            id: id ? id : null,
            role_id: role_id ? role_id : null,
        },
        fetchPolicy: 'network-only',
    })
    return data.data
})

export const listUsers = createAsyncThunk('base/admin/users', async({ column, admin, states, pageSize, pageIndex, query }) => {
    const { data } = await apolloMasterClient.query({
        query: MasterListUsersQuery,
        variables: {
            col: column === undefined ? 'email' : column,
            admin,
            states: states === undefined ? [] : states,
            paginate: {
                per_page: pageSize,
                page: pageIndex,
                search: query ?? ''
            }
        },
        fetchPolicy: "network-only",
    })
    return data.data
})

export const listCategories = createAsyncThunk('base/admin/categories', async({ 
    type, pageSize, pageIndex, query, active, visible
}) => {
    const { data } = await apolloMasterClient.query({
        query: MasterListCategoriesQuery,
        variables: {
            type: type ? type : '',
            active: typeof active !== 'boolean' ? true : active,
            visible: typeof visible !== 'boolean' ? true : visible,
            paginate: {
                per_page: pageSize ? pageSize : 20,
                page: pageIndex ? pageIndex : 1,
                search: query ? query : '',
            }
        },
        fetchPolicy: 'network-only'
    })
    return data?.data
})

export const HomeDashboard = createAsyncThunk('base/admin/home', async({ eId, start, end }) => {
    const { data } = await defaultClient.query({
        query: HomeDashboardQuery,
        variables: {
            eId,
            start,
            end
        },
        fetchPolicy: 'network-only'
    })

    return {
        ...data.data
    }
})

export const initialState = {
    listEstablishments: [],
    currentEstablishment: null,
    cities: {
        loading: false,
        data: [],
        pageSize: 100,
        pageTotal: 1,
        pageIndex: 1,
        total: 0,
    },
}

export const adminSlice = createSlice({
    name: 'base/admin',
    initialState,
    reducers: {
        setListEstablishments: (state, action) => {
            state.listEstablishments = action.payload
        },
        setCurrentEstablishment: (state, action) => {
            state.currentEstablishment = action.payload
        },
        setDataCities: (state, action) => {
            state.cities.data = action.payload.data
            state.cities.pageSize = action.payload.pageSize
            state.cities.pageIndex = action.payload.pageIndex
            state.cities.pageTotal = action.payload.pageTotal
            state.cities.total = action.payload.total
        }
    },
    extraReducers: {
        
    }
})

export const {
    setListEstablishments,
    setCurrentEstablishment,
    setDataCities,
} = adminSlice.actions

export default adminSlice.reducer