import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apolloMasterClient } from "apollo";
import { MasterListEstablishmentApplicationsQuery } from "apollo/queries/masters";

const initialState = {
    dataList: [],
    loading: false,
    tableData: {
        total: 0,
        pageIndex: 0,
        pageSize: 10,
        query: '',
    },
}

export const listEstablishmentApplications = createAsyncThunk('masters/listEstablishmentApplications', async({ country_id, states, city_id, pageIndex, pageSize, query }) => {
    const { data } = await apolloMasterClient.query({
        query: MasterListEstablishmentApplicationsQuery,
        variables: {
            country_id: country_id ? country_id : null,
            states: states ? states : ['review', 'accepted'],
            city_id: city_id ? city_id : null,
            paginate: {
                search: query ? query : '',
                per_page: pageSize ? pageSize : 15,
                page: pageIndex ? pageIndex : 1,
            }
        },
        fetchPolicy: 'network-only'
    })
    return data?.data ?? {}
})

const establishmentApplications = createSlice({
    name: 'masters/establishmentApplications',
    initialState,
    reducers: {
        setQuery: (state, action) => {
            state.tableData.query = action.payload
        },
        setPageIndex: (state, action) => {
            state.tableData.pageIndex = action.payload
        },
        setPageSize: (state, action) => {
            state.tableData.pageSize = action.payload
        }
    },
    extraReducers: {
        [listEstablishmentApplications.pending]: (state, action) => {
            state.loading = true
        },
        [listEstablishmentApplications.fulfilled]: (state, action) => {
            state.dataList = action.payload.data
            state.tableData.total = action.payload.total
            state.tableData.pageIndex = action.payload.current_page
            state.tableData.pageSize = action.payload.per_page
            state.loading = false
        },
    }
});

export const {
    setQuery,
    setPageIndex,
    setPageSize
} = establishmentApplications.actions

export default establishmentApplications.reducer;