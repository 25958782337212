import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { domainURL } from "utils/helpers"

export const createApolloClient = (uri) => {
    let httpLink = createUploadLink({
        uri,
    })

    let link = ApolloLink.from([httpLink])

    return new ApolloClient({
        link,
        cache: new InMemoryCache()
    })
}

export const defaultClient = createApolloClient(domainURL(null, 'panel.default'))
export const graphDefaultClient = createApolloClient(domainURL(null, 'graph.default'))
export const graphDashboardClient = createApolloClient(domainURL(null, 'graph.dashboard'))
export const apolloMasterClient = createApolloClient(domainURL('/masters', 'panel.default'))

export default defaultClient;