import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createApolloClient } from "apollo"
import { ClosedEstablishmentTableMutation, GetOrdersByTableQuery, OpenEstablishmentTableMutation, StatusTableQuery } from "apollo/queries/orders"
import { findIndex } from "lodash"
import { domainURL } from "utils/helpers"

const initialState = {
    loading: false,
    status: 'available',
    statusList: [
        { label: 'Libres', value: 'available', total: 0, },
        { label: 'Mis mesas', value: 'my_tables', total: 0, },
        { label: 'Sin mesero', value: 'no_waiter', total: 0, },
        { label: 'Ocupados', value: 'busy', total: 0, },
    ],
    dataList: [],
    tableSelect: {
        table_id: null,
        table_number: 0,
        nro_persons: 1,
        nro_orders: 0,
        customer: {
            name: '',
            email: '',
            cellphone: '',
        }
    },
}

const apolloClient = createApolloClient(domainURL('/orders', 'panel.default'))

export const statusTablesList = createAsyncThunk('waiter/tables/statusList', async({ status, establishment_id, waiter_id }) => {
    const { data } = await apolloClient.query({
        query: StatusTableQuery,
        variables: {
            status: status ? status : [],
            establishment_id: establishment_id ? establishment_id : null,
            waiter_id: waiter_id ? waiter_id : null,
        },
        fetchPolicy: 'network-only',
    })
    return data?.data ?? [];
})

export const openTable = createAsyncThunk('waiter/tables/openTable', async({ table_id, nro_persons, customer, waiter_id }) => {
    const { data } = await apolloClient.mutate({
        mutation: OpenEstablishmentTableMutation,
        variables: {
            table_id: table_id ? table_id : null,
            nro_persons: nro_persons ? nro_persons : 0,
            customer: customer ? customer : {},
            waiter_id: waiter_id ? waiter_id : null,
        }
    })
    return data?.data
})

export const closeTable = createAsyncThunk('waiter/tables/closeTable', async({ table_id, waiter_id }) => {
    const { data } = await apolloClient.mutate({
        mutation: ClosedEstablishmentTableMutation,
        variables: {
            table_id: table_id ? table_id : null,
            waiter_id: waiter_id ? waiter_id : null,
        }
    })
    return data?.data
})

export const getOrdersByTable = createAsyncThunk('waiter/tables/getOrders', async({ table_id }) => {
    const { data } = await apolloClient.query({
        query: GetOrdersByTableQuery,
        variables: {
            table_id: table_id ? table_id : null,
        },
        fetchPolicy: 'network-only',
    })
    return data?.data ?? []
})

const tablesSlice = createSlice({
    name: 'waiter/tables',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setDataList: (state, action) => {
            state.dataList = action.payload
            let i = findIndex(state.statusList, { value: state.status })
            state.statusList[i].total = action.payload.length
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setTableSelect: (state, action) => {
            state.tableSelect = action.payload
        },
        resetTableSelect: (state) => {
            state.tableSelect = {
                table_id: null,
                table_number: 0,
                nro_persons: 1,
                nro_orders: 0,
                customer: {
                    name: '',
                    email: '',
                    cellphone: '',
                }
            }
        }
    }
})

export const {
    setStatus,
    setDataList,
    setLoading,
    setTableSelect,
    resetTableSelect,
} = tablesSlice.actions

export default tablesSlice.reducer;