import { gql } from "@apollo/client";

export const ORDER_BY_ID = gql`
query($id: Int!) {
  data: orderByID(id: $id) {
    id
    nro_order
    order_date
    reserved_date
    order_type
    order_type_text
    state
    state_text
    branch_office_id
    table_number
    type_payment
    preparation_time
    cancelled_date
    cancelled_motive
    cancelled_mode
    user_id
    total
    delivery_price
    user {
      id
      name
      full_name
      avatar
      email
      person {
        id
        first_name
        last_name
        phone
        address
      }
    }
    delivery_user_info {
      id
      avatar
      phone
      user_id
      user {
        id
        full_name
      }
    }
    details: detailsOrder {
      id
      item
      code
      description
      quantity
      price
      comment: instructions
      additionals {
        id
        description
        price
        quantity
      }
    }
  }
}
`

export const GetTableOrdersQuery = gql`query($status: [String], $establishment_id: ID!, $no_waiter: Boolean!, $paginate: PaginateInput!) {
  data: OrderGetTableOrders(status: $status, establishment_id: $establishment_id, no_waiter: $no_waiter, paginate: $paginate)
  {
    data {
      id
      nro_order
      table_number
      status
      paid_status
    }
    current_page
    last_page
    per_page
  }
}`