import { combineReducers } from "redux";
import companiesReducer from "./companies";
import establishmentsReducer from "./establishments";
import usersReducer from "./users";
import establishmentApplicationsReducer from "./establishmentApplications";
import cardsReducer from "./cards";

const mastersReducer = combineReducers({
    companies: companiesReducer,
    establishments: establishmentsReducer,
    users: usersReducer,
    establishmentApplications: establishmentApplicationsReducer,
    cards: cardsReducer,
})

export default mastersReducer;