import { createSlice } from "@reduxjs/toolkit"
import { each, findIndex, join, split, trimEnd, trimStart } from "lodash"

const initialState = {
    tableSelected: null,
    items: [],
    model: {
        table_number: null,
        order_type: 3,
        user_info: {
            name: '',
            email: '',
            given_name: '',
            sur_name: '',
            cellphone: '',
        },
        status: 'waiting',
        paid_status: 'waiting',
        payment_type: 'unknown',
        env_type_id: '02',
        detail: '',
        observation: '',
    },
    total: 0,
}

const checkOutSlice = createSlice({
    name: 'waiter/checkout',
    initialState,
    reducers: {
        setTableCheckOut: (state, action) => {
            state.tableSelected = action.payload
            if(state.tableSelected) {
                const names = split(state.tableSelected?.customer?.name ?? '', ',', 2)
                const givenName = names[0] ? trimStart(trimEnd(names[0])) : ''
                const surName = names[1] ? trimStart(trimEnd(names[1])) : ''
                state.model = {
                    ...state.model,
                    table_number: state.tableSelected?.table_number,
                    user_info: {
                        name: join([givenName, surName], ', '),
                        cellphone: state.tableSelected?.customer?.cellphone ?? '',
                        email: state.tableSelected?.customer?.email ?? '',
                        given_name: givenName,
                        sur_name: surName,
                    }
                }
            }
        },
        addItemCheckOut: (state, action) => {
            let index = findIndex(state.items, { item_id: action.payload.item_id })
            if(index === -1) {
                state.items = [...state.items, action.payload]
            } else {
                state.items[index].price = parseFloat(action.payload.price)
                state.items[index].quantity = parseFloat(state.items[index].quantity) + parseFloat(action.payload.quantity)
                state.items[index].products_total = ((state.items[index]?.quantity ?? 0) * state.items[index].price)
            }

            let total = 0
            each(state.items, (item) => total += item.products_total)
            state.total = total
        },
        removeItemCheckOut: (state, action) => {
            state.items.splice(action.payload, 1)

            let total = 0
            each(state.items, (item) => total += item.products_total)
            state.total = total
        },
        setChangeState: (state, action) => {
            state.model.state = action.payload
        },
        setUserInfo: (state, action) => {
            const names = split(action.payload?.name ?? '', ',', 2)
            const givenName = names[0] ? trimStart(trimEnd(names[0])) : ''
            const surName = names[1] ? trimStart(trimEnd(names[1])) : ''
            state.model = {
                ...state.model,
                user_info: {
                    name: join([givenName, surName], ', '),
                    given_name: givenName,
                    sur_name: surName,
                    cellphone: action.payload?.cellphone,
                    email: action.payload?.email,
                }
            }
        },
        setUserInfoField: (state, action) => {
            state.model.user_info[action.payload?.key] = action.payload?.value
            
            if(action.payload?.key === 'name') {
                const names = split(action.payload?.value ?? '', ',', 2)
                const givenName = names[0] ? trimStart(trimEnd(names[0])) : ''
                const surName = names[1] ? trimStart(trimEnd(names[1])) : ''

                state.model.user_info.name = join([givenName, surName], ', ')
                state.model.user_info.given_name = givenName
                state.model.user_info.sur_name = surName
            }
        },
        resetCheckOut: (state) => {
            state.items = []
            state.tableSelected = null
            state.model = {
                table_number: null,
                order_type: 3,
                user_info: {
                    name: '',
                    email: '',
                    given_name: '',
                    sur_name: '',
                    cellphone: '',
                },
                state: 'waiting',
                paid_state: 'waiting',
                payment_type: 'unknown',
                env_type_id: '02',
                detail: '',
                observation: '',
            }
            state.total = 0
        }
    }
})

export const {
    setTableCheckOut,
    addItemCheckOut,
    removeItemCheckOut,
    setChangeState,
    setUserInfo,
    setUserInfoField,
    resetCheckOut,
} = checkOutSlice.actions

export default checkOutSlice.reducer