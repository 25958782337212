import { gql } from "@apollo/client";

export const StatusTableQuery = gql`query($status: [String], $establishment_id: ID, $waiter_id: ID) {
    data: OrderStatusTables(status: $status, establishment_id: $establishment_id, waiter_id: $waiter_id) {
        id
        table_id
        establishment_id
        table_number
        nro_persons
        nro_orders
        status
        opened_at
        closed_at
        customer {
            name
            email
            cellphone
        }
        waiter_id
        waiter_name
        observation
        is_closed
    }
}`

export const OpenEstablishmentTableMutation = gql`mutation($table_id: ID!, $nro_persons: Int!, $customer: PersonInput, $waiter_id: ID) {
   data: OrderOpenEstablishmentTable(table_id: $table_id, nro_persons: $nro_persons, customer: $customer, waiter_id: $waiter_id) {
    success
    message
   }
}`

export const ClosedEstablishmentTableMutation = gql`mutation($table_id: ID!, $waiter_id: ID) {
    data: OrderClosedEstablishmentTable(table_id: $table_id, waiter_id: $waiter_id) {
        success
        message
    }
}`

export const GetOrdersByTableQuery = gql`query($table_id: ID!) {
    data: OrderGetOrdersByTable(table_id: $table_id) {
        id
        nro_order
        status
        paid_status
        tip_amount
        total_amount

        items {
            id
            description
            quantity
            price
            total
        }
    }
}`