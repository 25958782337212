export const domainConfig = {
    development: {
        _: 'https://tynda.test',
        panel: {
            _: 'https://tynda-panel.test',
            default: 'https://tynda-panel.test/graphql',
        },
        graph: {
            _: 'https://tynda-graph.test',
            default: 'https://tynda-graph.test/graphql',
            dashboard: 'https://tynda-graph.test/graphql/dashboard',
        },
        qr: {
            _: 'https://apptynda.com',
        },
    },
    production: {
        _: 'https://apptynda.com',
        panel: {
            _: 'https://env.apptynda.com',
            default: 'https://env.apptynda.com/graphql',
        },
        graph: {
            _: 'https://graph.apptynda.com',
            default: 'https://graph.apptynda.com/graphql',
            dashboard: 'https://graph.apptynda.com/graphql/dashboard',
        },
        qr: {
            _: 'https://apptynda.com',
        },
    },
}

export default domainConfig
