import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apolloMasterClient, defaultClient } from "apollo";
import { EstablishmentListAdministratorsQuery } from "apollo/queries/establishments";
import { MasterListEstablishmentsQuery } from "apollo/queries/masters";

const initialState = {
    dataList: [],
    loading: false,
    tableData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
        query: '',
        sort: {
            order: '',
            key: '',
        },
    }
}

export const allEstablishments = createAsyncThunk('masters/establishments/list', async({ company_id, city_id, pageSize, pageIndex, query }) => {
    const { data } = await apolloMasterClient.query({
        query: MasterListEstablishmentsQuery,
        variables: {
            company_id: company_id === undefined ? null : company_id,
            city_id: city_id === undefined ? 0 : city_id,
            paginate: {
                page: pageIndex,
                per_page: pageSize,
                search: query === undefined ? '' : query,
            }
        },
        fetchPolicy: 'network-only',
    })
    return data?.data ?? {}
})

export const listAdministrators = createAsyncThunk('masters/establishments/administrators', async(establishmentId) => {
    const { data } =  await defaultClient.query({
        query: EstablishmentListAdministratorsQuery,
        variables: {
            eid: establishmentId
        },
        fetchPolicy: 'network-only'
    })
    return data?.data ?? {}
});

const establishmentsReducer = createSlice({
    name: 'masters/establishments',
    initialState,
    reducers: {
        setQuery: (state, action) => {
            state.tableData.query = action.payload
        },
        setPageIndex: (state, action) => {
            state.tableData.pageIndex = action.payload
        },
        setPageSize: (state, action) => {
            state.tableData.pageSize = action.payload
        },
    },
    extraReducers: {
        [allEstablishments.pending]: (state) => {
            state.loading = true
        },
        [allEstablishments.fulfilled]: (state, action) => {
            state.dataList = action.payload.data
            state.tableData.pageIndex = action.payload.current_page
            state.tableData.pageSize = action.payload.per_page
            state.loading = false
        }
    }
})

export const {
    setQuery,
    setPageIndex,
    setPageSize,
} = establishmentsReducer.actions

export default establishmentsReducer.reducer