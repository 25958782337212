import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
    network: {
        ip: null,
        countryCode: null,
        country: null,
    },
    settings: [
        {
            label: 'Correo electrónico',
            name: 'support_email',
            value: 'apptynda@gmail.com',
            group: 'app',
            is_global: true,
        }
    ],
}

export const appSlice = createSlice({
    name: 'base/app',
    initialState,
    reducers: {
        setGlobalSettings: (state, action) => {
            state.settings = action.payload;
        },
        setNetwork: (state, action) => {
            state.network = action.payload;
        }
    }
})

export const {
    setGlobalSettings,
    setNetwork
} = appSlice.actions

export default appSlice.reducer