import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './custom.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

console.log('%c TYNDA TECHNOLIGIES %c EstevanTN ', 'font-size:12px; background:#00CCBC; padding:5px; color: #fff;font-weight: bold; border-radius: 5px 0 0 5px;box-shadow: 0 2px 6px rgba(0,0,0,.3);', 'background:#777; color: #fff; padding:5px;border-radius: 0 5px 5px 0;box-shadow: 0 2px 6px rgba(0,0,0,.3);');

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
