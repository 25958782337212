import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createApolloClient, graphDefaultClient } from "apollo"
import { addOrderItemMutation, addOrderTableMutation } from "apollo/mutations/default"
import { GetTableOrdersQuery } from "apollo/queries/default"
import { upperCase } from "lodash"
import { isObject } from "lodash"
import { domainURL, getBrowser, getOperatingSystem } from "utils/helpers"

const initialState = {
    status: 'none',
    tabStatus: [
        {
            label: 'Sin mesero',
            total: 0,
            value: 'none',
        },
        {
            label: 'Recientes',
            total: 0,
            value: 'waiting',
        },
        {
            label: 'Preparados',
            total: 0,
            value: 'preparing',
        },
        {
            label: 'Entregados',
            total: 0,
            value: 'delivered',
        }
    ],
    dataList: [],
    tableData: {
        pageSize: 10,
        pageIndex: 1,
        total: 0,
        query: '',
    }
}

const ordersClient = createApolloClient(domainURL('/orders', 'panel.default'))

export const listTableOrders = createAsyncThunk('waiter/orders/tableOrders', async({ status, establishment_id, no_waiter, pageIndex, pageSize, search }) => {
    const { data } = await ordersClient.query({
        query: GetTableOrdersQuery,
        variables: {
            status: status ? status : [],
            establishment_id: establishment_id ? establishment_id : 0,
            no_waiter: no_waiter ? no_waiter : false,
            paginate: {
                per_page: pageSize ? pageSize : 10,
                page: pageIndex ? pageIndex : 1,
                search: search ? search : '',
            }
        },
        fetchPolicy: 'network-only',
    })
    return data?.data
}) 

export const addItemToOrder = createAsyncThunk('waiter/orders/addItem', async({ id, nro_order, code, description, quantity, price, additionals, instructions, um = 'NIU' }) => {
    const { data } = await graphDefaultClient.mutate({
        mutation: addOrderItemMutation,
        variables: {
            id: id ? id : 0,
            nro_order: nro_order ? nro_order : '',
            code: code ? code : 0,
            description: description ? description : 0,
            quantity: quantity ? parseFloat(quantity) : 0,
            price: price ? parseFloat(price) : 0,
            additionals: additionals ? additionals : [],
            instructions: instructions ? instructions : '',
            um: um ? um : 'NIU',
        }
    })
    return data?.data
})

export const addOrderTable = createAsyncThunk('waiter/orders/addOrderTable', async({
    branch_office_id, name, email, cellphone, payment_type, details,
    referencial_id, table_number, tip_comission, version
}) => {
    const { data } = await graphDefaultClient.mutate({
        mutation: addOrderTableMutation,
        variables: {
            branch_office_id: branch_office_id ? branch_office_id : 0,
            name: name ? name : '',
            email: email ? email : '',
            cellphone: cellphone ? cellphone : '',
            payment_type: payment_type ? upperCase(payment_type) : 'CASH',
            details: details ? details : [],
            referencial_id: referencial_id ? referencial_id : null,
            table_number: table_number ? table_number : null,
            tip_comission: tip_comission ? tip_comission : null,
            version: isObject(version) ? version : {
                number: '2.0.1',
                device: 'Desktop',
                user_agent: getBrowser(window),
                device_os: getOperatingSystem(window),
            },
        }
    })
    return data?.data
})

const ordersSlice = createSlice({
    name: 'waiter/orders',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setDataList: (state, action) => {
            state.tableData.pageIndex = action.payload.current_page
            state.tableData.pageSize = action.payload.per_page
            state.dataList = action.payload.data
        }
    }
})

export const {
    setStatus,
    setDataList,
} = ordersSlice.actions

export default ordersSlice.reducer