import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import app from './appSlice'
import admin from './adminSlice'

const reducer = combineReducers({
    app,
    common,
    admin,
})

export default reducer
