const appConfig = {
    apiPrefix: 'https://env.apptynda.com/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'es',
    enableMock: false,
}

export default appConfig
