import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apolloMasterClient } from "apollo";
import { MasterListCardsQuery } from "apollo/queries/masters";

const initialState = {
    loading: false,
    showForm: false,
    dataList: [],
    pagingData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
        query: '',
    },
}

export const listCards = createAsyncThunk('masters/cards/list', async({ country_id, establishment_id, city_id, query, pageIndex, pageSize }) => {
    const { data } = await apolloMasterClient.query({
        query: MasterListCardsQuery,
        variables: {
            country_id: country_id ? country_id : 'PE',
            city_id: city_id ? city_id : 0,
            establishment_id: establishment_id ? establishment_id : '',
            paginate: {
                search: typeof query === 'undefined' ? query : '',
                page: typeof pageIndex === 'number' ? pageIndex : 1,
                per_page: typeof pageSize === 'number' ? pageSize : 10,
            }
        },
        fetchPolicy: 'network-only',
    })
    return data?.data
})

const cardsSlice = createSlice({
    name: 'masters/cards',
    initialState,
    reducers: {
        setShowForm: (state, action) => {
            state.showForm = action.payload
        },
        setQuery: (state, action) => {
            state.pagingData.query = action.payload
        },
        setPageSize: (state, action) => {
            state.pagingData.pageSize = action.payload
        },
        setPageIndex: (state, action) => {
            state.pagingData.pageIndex = action.payload
        },
    },
    extraReducers: {
        [listCards.pending]: (state) => {
            state.loading = true;
        },
        [listCards.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataList = action.payload.data
            state.pagingData.total = action.payload.total
            state.pagingData.pageIndex = action.payload.page
            state.pagingData.pageSize = action.payload.per_page
        }
    }
})

export const {
    setShowForm,
    setQuery,
    setPageIndex,
    setPageSize,
} = cardsSlice.actions

export default cardsSlice.reducer;