import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apolloMasterClient } from "apollo";
import { MasterCompanyMutation } from "apollo/mutations/masters";
import { MasterGetCompanyByIdQuery, MasterListCompaniesQuery } from "apollo/queries/masters";

const initialState = {
    dataList: [],
    loading: false,
    states: ['accepted'],
    cities: [],
    countries: [],
    selectedCountry: null,
    selectedCity: null,
    formData: {
        show: false,
        companyId: null,
    },
    tableData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
        query: '',
        sort: {
            order: '',
            key: '',
        },
    }
}

export const listCompanies = createAsyncThunk('masters/companies/list', async({ states, cityId, pageIndex, pageSize, query, active, trashed }) => {
    const { data } = await apolloMasterClient.query({
        query:  MasterListCompaniesQuery,
        variables: {
            states,
            cityId,
            active: active !== undefined ? active : true,
            trashed: trashed !== undefined ? trashed : true,
            paginate: {
                per_page: pageSize,
                page: pageIndex,
                search: query === undefined ? '' : query,
            }
        },
        fetchPolicy: 'network-only'
    })
    return data?.data ?? {}
})

export const getCompany = createAsyncThunk('masters/companies/get', async(id) => {
    const { data } = await apolloMasterClient.query({
        query: MasterGetCompanyByIdQuery,
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    })
    return data?.data ?? {}
})

export const storeCompany = createAsyncThunk('masters/companies/store', async({ id, identifier_number, legal_name, 
    trade_name, address, state, active, country_id, city_id }) => {
    const { data } = await apolloMasterClient.mutate({
        mutation: MasterCompanyMutation,
        variables: {
            id: id === undefined ? 0 : id,
            identifier: identifier_number === undefined ? '' : identifier_number,
            legal_name,
            trade_name,
            address,
            country_id,
            city_id,
            state,
            active: active === undefined ? false : active,
        }
    })
    return data?.data ?? {}
})

const companiesSlice = createSlice({
    name: 'masters/companies',
    initialState,
    reducers: {
        setStates: (state, action) => {
            state.states = action.payload
        },
        setQuery: (state, action) => {
            state.tableData.query = action.payload
        },
        setPageIndex: (state, action) => {
            state.tableData.pageIndex = action.payload
        },
        setPageSize: (state, action) => {
            state.tableData.pageSize = action.payload
        },
        setFormData: (state, action) => {
            state.formData = action.payload
        },
        setCountries: (state, action) => {
            state.countries = action.payload
        },
        setCities: (state, action) => {
            state.cities = action.payload
        },
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload
        },
        setSelectedCity: (state, action) => {
            state.selectedCity = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: {
        [listCompanies.pending]: (state) => {
            state.loading = true
        },
        [listCompanies.fulfilled]: (state, action) => {
            state.dataList = action.payload.data
            state.tableData.total = action.payload.total
            state.tableData.pageIndex = action.payload.current_page
            state.tableData.pageSize = action.payload.per_page
            state.loading = false
        }
    }
})

export const {
    setQuery,
    setPageIndex,
    setPageSize,
    setStates,
    setFormData,
    setCities,
    setCountries,
    setSelectedCountry,
    setSelectedCity,
    setLoading,
} = companiesSlice.actions

export default companiesSlice.reducer;