import { gql } from "@apollo/client";


export const addOrderItemMutation = gql`mutation($id: ID, $nro_order: String!, $code: String!, $description: String!, $price: Float!, $quantity: Float!, $additionals: [OrderDetailAdditionalInput], $instructions: String, $um: String) {
    data: AddOrderItem(id: $id, nro_order: $nro_order, code: $code, description: $description, price: $price, quantity: $quantity, additionals: $additionals, instructions: $instructions, unit_measure: $um)
    {
        success
        message
    }
}`

export const addOrderTableMutation = gql`mutation($branch_office_id: Int!, $name: String!, $email: String, $cellphone: String, $payment_type: Payment, $details: [OrderDetailInput]!, $referencial_id: Int, $table_number: Int!, $tip_comission: Float, $version: VersionInput) {
    data: addOrderTable(branch_office_id: $branch_office_id, name: $name, email: $email, cellphone: $cellphone, payment_type: $payment_type, details: $details, referencial_id: $referencial_id, table_number: $table_number, tip_comission: $tip_comission, version: $version) {
        success
        message
    }
}`