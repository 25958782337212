import { combineReducers } from "redux";
import tables from "./tables";
import checkout from "./checkout";
import orders from "./orders";

const waiterReducer = combineReducers({
    tables,
    orders,
    checkout,
})

export default waiterReducer