import {
    find,
    get,
    has,
    includes,
    isArray,
    isObject,
    isString,
    map,
    merge,
} from 'lodash'
import { useSelector } from 'react-redux'
import 'moment/locale/es';
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import store from '../store'
import queryString from 'query-string'
import domainConfig from 'configs/domain.config'
import { ELOQUENT_MODELS } from 'constants/models.constant'

export const listMonths = [
    { label: 'Todos', value: 0 },
    { label: 'Enero', value: 1 },
    { label: 'Febrero', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Mayo', value: 5 },
    { label: 'Junio', value: 6 },
    { label: 'Julio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Septiembre', value: 9 },
    { label: 'Octubre', value: 10 },
    { label: 'Noviembre', value: 11 },
    { label: 'Diciembre', value: 12 },
]

export const removeProps = (obj, keys = ['__typename']) => {
    if (isArray(obj)) {
        return map(obj, (item) => {
            return removeProps(item, keys)
        })
    }

    const target = {}
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue
        target[i] = obj[i]
    }
    return target
}

export const user = (prop, def = null) =>
    get(store.getState().auth?.user, prop, def)
export const userToken = () => user('token')

export const windowDialog = (...args) => {
    const options = args.length === 2 ? args[1] : {}
    let url = isString(args[0]) ? queryString.parseUrl(args[0]) : args[0]
    url = merge(url, { query: { sso_token: userToken() } })
    const dialog = window.open(
        queryString.stringifyUrl(url, {
            skipNull: true,
            arrayFormat: 'bracket',
            //parseNumbers: true,
            //parseBooleans: true,
            parseFragmentIdentifier: true,
        }),
        '_blank',
        queryString.stringify(options).replace('&', ',')
    )
    return dialog
}

export const findSetting = (predicate, defValue = '') => {
    const countryId = store.getState().locale.countryId
    const settings = store.getState().base.app.settings
    predicate = merge({ country_id: countryId }, predicate)
    let setting = find(settings, predicate)
    if (setting === null || setting === undefined) {
        setting = isObject(defValue) ? defValue : { value: defValue, label: '' }
    }
    return setting
}

export const useLocaleFormats = () => {
    const currencyCode = useSelector((state) => state.locale.currencyCode)
    const timeZone = useSelector((state) => state.locale.timeZone)
    const locale = useSelector((state) => state.locale.lang)
    const countryId = useSelector((state) => state.locale.countryId)

    const localeCountry = useMemo(() => {
        const _locale = locale.replace('_', '-').split('-')
        moment.locale('es')
        return `${_locale[0]}-${countryId ?? 'PE'}`
    }, [locale, countryId])

    const formatCurrencySymbol = useCallback(
        (amount) => {
            //const a = formatCurrency({ amount: Number(amount), code: currencyCode, })
            //const formatter = new Intl.NumberFormat(localeCountry, {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currencyCode,
                // These options are needed to round to whole numbers if that's what you want.
                minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
            })
            return formatter.format(Number(amount ?? 0))
        },
        [currencyCode]
    )

    const fromTz = useCallback(
        (time, tz = null, fTz = null) => {
            fTz = (fTz === null || fTz === undefined) ? 'America/Lima' : fTz
            tz = (tz === null || tz === undefined) ? timeZone : tz
            fTz = includes(['lima', 'default'], fTz) ? 'America/Lima' : fTz
            tz = includes(['lima', 'default'], tz) ? 'America/Lima' : tz
            const m =
                (time === null || time === undefined)
                    ? moment.tz(new Date(), fTz)
                    : moment.tz(time, fTz)
            return m.clone().tz(tz)
        },
        [timeZone]
    )

    const toTz = useCallback(
        (time = null, tz = null) => {
            time = fromTz(time, tz, null)
            tz = includes(['lima', 'default'], tz) ? 'America/Lima' : tz
            tz = tz === null || tz === undefined ? timeZone : tz
            return fromTz(time, tz, null)
        },
        [timeZone, fromTz]
    )

    const toMoment = useCallback(
        (time = null) => {
            return toTz(time, timeZone)
        },
        [timeZone, toTz]
    )

    const toHumans = useCallback((time) => {
        return fromTz(time).fromNow(true)
    }, [fromTz])

    const formatDate = useCallback((time) => toMoment(time).toDate(), [toMoment])
    const formatDateString = (time, f = 'DD/MM/YYYY hh:mm:ss a') =>
        toMoment(time).format(f)
    const formatDateShortString = (time, f = 'DD/MM/YYYY') =>
        toMoment(time).format(f)
    const formatTimeString = (time, f = null) =>
        toMoment(time).format(f ? f : 'hh:mm:ss a')
    const formatDateDbString = (time, f = 'YYYY-MM-DD HH:mm:ss') =>
        toTz(time, 'lima').format(f)
    const formatTimeDbString = (time, f = 'hh:mm:ss') =>
        toTz(time, 'lima').format(f)

    return {
        moment,
        fromTz,
        toTz,
        toMoment,
        formatCurrency: formatCurrencySymbol,
        formatDate,
        formatDateString,
        formatDateShortString,
        formatTimeString,
        formatDateDbString,
        formatTimeDbString,
        toHumans,
        localeCountry,
    }
}

/**
 *
 * @param {String|null} uri
 * @param {String} name
 * @param {mixed} env
 * @returns
 */
export function domainURL(uri = '', name = '_', env = null) {
    uri = uri === null || uri === undefined ? '' : uri
    env = env === null || env === undefined ? process.env.NODE_ENV : env
    env = ['development', 'dev', 'test'].includes(env) ? 'development' : env
    const baseUrl = get(domainConfig, `${env}.${name}`)
    return [baseUrl, ...uri].join('')
}

/**
 *
 * @param {String|null} typeClass
 * @param {String|null} defaultClass
 * @returns
 */
export const findEloquentClass = (typeClass, defaultClass = null) => {
    return has(ELOQUENT_MODELS, typeClass) ? get(ELOQUENT_MODELS, typeClass, defaultClass) : defaultClass
}

export const urlTableQR = (design, item = {}, download = false) => {
    const qStr = queryString.stringify({
        ...item,
        design,
    })
    return domainURL(`/qr-code/tables${download ? '/download' : ''}?${qStr}`, 'panel._')
}

export const getOperatingSystem = (window) => {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Windows OS'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'MacOS'; }
    if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX OS'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux OS'; }
    return operatingSystem;
}

export const getBrowser = (window) => {
    let currentBrowser = 'Not known';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
    else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
    else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
    else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
    else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
    return currentBrowser;
}