import { useState, useEffect } from 'react';

export const DEVICE_TYPE_XS = 'xs'
export const DEVICE_TYPE_MD = 'md'
export const DEVICE_TYPE_LG = 'lg'
export const DEVICE_TYPE_XL = 'xl'

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(DEVICE_TYPE_LG);

  const getDeviceType = (width) => {
    if (width < 768) {
      return DEVICE_TYPE_XS;
    } else if (width >= 768 && width < 1024) {
      return DEVICE_TYPE_MD;
    } else if (width >= 1024 && width < 1920) {
      return DEVICE_TYPE_LG;
    } else {
      return DEVICE_TYPE_XL;
    }
  };

  const handleResize = () => {
    const currentWidth = window.innerWidth;
    setDeviceType(getDeviceType(currentWidth));
  };

  useEffect(() => {
    // Set the initial device type
    handleResize();

    // Add event listener to track window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return deviceType;
};

export default useDeviceType;