import { createSlice } from '@reduxjs/toolkit'
import appConfig from 'configs/app.config'

const initialState = {
    lang: appConfig.locale,
    currencyCode: 'PEN',
    countryId: 'PE',
    zipCode: 51,
    timeZone: 'America/Lima',
}

export const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLang: (state, action) => {
            state.lang = action.payload
        },
        setCurrencyCode: (state, action) => {
            state.currencyCode = action.payload
        },
        setCountryId: (state, action) => {
            state.countryId = action.payload
        },
        setZipCode: (state, action) => {
            state.zipCode = action.payload
        },
        setTimeZone: (state, action) => {
            state.timeZone = action.payload
        }
    },
})

export const { 
    setLang,
    setCurrencyCode,
    setCountryId,
    setZipCode,
    setTimeZone,
} = localeSlice.actions

export default localeSlice.reducer
