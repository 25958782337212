import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apolloMasterClient } from "apollo";
import { MasterStoreUserMutation } from "apollo/mutations/masters";

const initialState = {
    dataList: [],
    loading: false,
    column: 'email',
    admin: false,
    states: ['active'],
    tableData: {
        total: 0,
        pageIndex: 1,
        pageSize: 10,
        query: '',
        sort: {
            order: '',
            key: '',
        },
    }
}

export const storeUser = createAsyncThunk('masters/users/store', async({ external_id, first_name, last_name, username, email, status, is_admin, is_root, country_id, city_id, password, repeat_password }) => {
    const { data } = await apolloMasterClient.mutate({
        mutation: MasterStoreUserMutation,
        variables: {
            user: {
                external_id: external_id ?? null,
                first_name: first_name ?? '',
                last_name: last_name ?? '',
                username: username ?? '',
                email: email ?? '',
                status: status ?? 'active',
                is_admin: typeof is_admin === 'boolean' ? is_admin : false,
                is_root: typeof is_root === 'boolean' ? is_root : false,
                country_id: country_id,
                city_id: city_id,
                password: password ?? '',
                repeat_password: repeat_password ?? '',
            }
        }
    })
    return data?.data ?? {}
})

const usersSlice = createSlice({
    name: 'masters/users',
    initialState,
    reducers: {
        setQuery: (state, action) => {
            state.tableData.query = action.payload
        },
        setPageIndex: (state, action) => {
            state.tableData.pageIndex = action.payload
        },
        setPageSize: (state, action) => {
            state.tableData.pageSize = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setAdmin: (state, action) => {
            state.admin = action.payload
        },
        setStates: (state, action) => {
            state.states = action.payload
        },
        setData: (state, action) => {
            state.dataList = action.payload.data
            state.tableData.total = action.payload.total
            state.tableData.pageIndex = action.payload.current_page
            state.tableData.pageSize = action.payload.per_page
        },
        setColumn: (state, action) => {
            state.column = action.payload
        }
    },
})

export const {
    setQuery,
    setPageIndex,
    setPageSize,
    setAdmin,
    setStates,
    setLoading,
    setData,
    setColumn,
} = usersSlice.actions

export default usersSlice.reducer