import { gql } from "@apollo/client";

export const MasterStoreSetting = gql`mutation($setting: SettingInput) {
    data: MasterStoreSetting(setting: $setting) {
        success
        message
    }
}`

export const MasterCancelPromotion = gql`mutation($external_id: String!){
    data: MasterCancelPromotion(external_id: $external_id) {
        success
        message
    }
}`

export const MasterStoreAwardMutation = gql`mutation($award: AwardInput, $uploads: [Upload]) {
    data: MasterStoreAward(award: $award, uploads: $uploads) {
        success
        message
    }
}`

export const MasterCompanyMutation = gql`mutation($id: Int, $identifier: String, $legal_name: String!, $trade_name: String,
    $address: String, $country_id: String!, $city_id: Int!, $state: String!, $active: Boolean!) {
    data: MasterStoreCompany(id: $id, identifier_number: $identifier, legal_name: $legal_name, trade_name: $trade_name,
        address: $address, country_id: $country_id, city_id: $city_id, state: $state, active: $active) {
        success
        message
        id
    }
}`

export const MasterStoreAccessableMutation = gql`mutation($eid: String!, $uid: Int!, $rid: Int, $authority: String) {
    data: MasterStoreAccessable(establishment_id: $eid, user_id: $uid, role_id: $rid, authority: $authority) {
        success
        message
    }
}`

export const MasterDestroyAccessableMutation = gql`mutation($id: Int!) {
    data: MasterDestroyAccessable(accessable_id: $id) {
        success
        message
    }
}`

export const MasterStoreUserMutation = gql`mutation($user: UserInput) {
    data: MasterStoreUser(user: $user) {
        success
        message
        id
    }
}`

export const MasterDestroyRecordMutation = gql`mutation($type: String!, $id: ID!) {
    data: MasterDestroyRecord(modelable_type: $type, modelable_id: $id) {
        success
        message
    }
}`

export const MasterStoreScheduleMutation = gql`mutation($schedule: ScheduleInput) {
    data: MasterStoreSchedule(schedule: $schedule) {
        success
        message
    }
}`